body {
  background-image: radial-gradient(ellipse at center, black, #171934, #1e2144);
  color: white;
  margin: 0; /* Remove default body margin to fill the entire viewport */
  height: 100vh; /* Set body height to 100% of the viewport height */
  display: flex;
  flex-direction: column;
  background-attachment: fixed;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

#policyFooter a {
  text-decoration: none;
  color: white;
}

#policyFooter a:hover {
  text-decoration: underline;
}