/*@media(prefers-reduced-motion) {
    .hidden {
        transition: none;
    }
}*/

.transitionSection {
    display: grid;
    align-content: center;
    min-height: 100vh;
    overflow: hidden;
}

.transitionSectionContent {
    transition: all 1s;
}