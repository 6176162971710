.input {
    border-radius: 8px;
    padding: 8px 0;
    margin-right: 8px;
}

.input:hover {
    cursor: pointer;
}


.smallButton {
    width: 70px;
    border: none;
}