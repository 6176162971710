/* src/components/StatusModal.module.css */

.modalContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 1000;
  pointer-events: none; /* Allow clicks to pass through */
  transition: all 0.3s ease;
}

.modal {
    background: black;
    padding: 1rem;
    border-radius: 12px;
    max-width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.3), 0 0 15px rgba(255, 255, 255, 0.6); /* Added halo effect */
}
  
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.1);
    border-left-color: #3498db;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
  
  .successCircle {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #28a745;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .checkmark {
    width: 24px;
    height: 24px;
    stroke-dasharray: 50;
    stroke-dashoffset: 50;
    animation: drawCheckmark 1s ease forwards;
  }
  
  .errorIcon {
    font-size: 24px;
    color: red;
  }
  
  .message {
    font-size: 16px;
    color: white;
    margin-left: 1rem;
  }


.slideDown {
  transform: translateY(20%);
}

.slideUp {
  transform: translateY(-140%);
}
  
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes drawCheckmark {
  to {
    stroke-dashoffset: 0;
  }
}
